<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <img src="../../../public/logo/mobilenew.png" class="" style="width: 200px" />
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCardGroup>
            <CCard class="">
              <CCardBody>
                <div class="text-dark bg-white text-left p-3">
                  <h3>Privacy Policy</h3></br>
                  <h4>Handling of Personal Information </h4>
                  <p>
                    Mitsubishi Electric India Pvt. Ltd. a company established and registered under the laws of India
                    (hereinafter be referred as "Mitsubishi Electric") sometimes collects personal and sensitive
                    personal information directly from its Employee's during employment in the course of fulfilling its
                    employee related obligation / services / and other statutory requirements and from its Business
                    Partner's (Channel partners / Distributors) / service provider's directly during appointment /
                    registration for meeting its business obligation and other related services and personal information
                    from Customer's through our ME-Connect (Mobile Application) for providing & delivery information
                    about Company's products & other services [Employee / Business Partner / service provider / Customer
                    collectively hereinafter referred to as 'You']. This policy shall regulate the collection, storage,
                    use, disclosure, retention and transfer and deletion of personal information which we receive from
                    the user of the ME-Connect.
                  </p>
                  <h4>Your Consent</h4>
                  <div>
                    You are requested to carefully read and understand this Policy document before sharing your personal
                    information. By using this ME-Connect mobile app, you consent to the terms of our privacy policy
                    and agree to collection, storage, use, disclosure, transfer and processing of your personal
                    information for the purposes given above.
                  </div> <br>
                  <h4>Application of Privacy Policy </h4>
                  <p>
                    This privacy policy of Mitsubishi Electric India Pvt. Ltd. applies to everyone who is a resident of
                    India and uses our ME-Connect mobile app and share their data to us out of free consent directly or
                    while using the ME-Connect.
                  </p>
                  <h4>1. Collecting Personal Information</h4>
                  <ol>
                    <li>Mitsubishi Electric collects personal & personal sensitive information by legal and fair
                      means.</li>
                    <li>
                      When we directly collect personal & personal sensitive information from you in writing, we will
                      specify how we intend to use the information provide contact information and ask for your consent.
                    </li>
                    <li>
                      Whether or not you provide personal information to Mitsubishi Electric is up to you. Please
                      note, however, that there are some services we may be unable to provide without your personal
                      information. When you provide personal information to Mitsubishi Electric, you agree to the terms
                      and conditions described in this section concerning this privacy policy. If you do not agree to
                      the terms and conditions herein, please note that some services we offer (including responses to
                      your inquiries) may not be available to you.
                    </li>
                  </ol>
                  <h4>2. The Personal Information We Collect </h4>
                  <ol>
                    <li>Mitsubishi Electric uses the personal & personal sensitive information we receive from
                      employees, Business Partners, Customers & Service provider to execute contracts and transactions
                      concluded between each of employee / Business Partner / service provider/customer and Mitsubishi
                      Electric or to arrange a call from our authorized person / service centre for product related
                      information to our customers as enquired or requested on our ME-Connect mobile app. Collecting the
                      data from you helps us understand what you are looking from the company, enabling us to deliver
                      improved products and services.</li>
                    <li>
                      Mitsubishi Electric may be entrusted by a client company to process personal information. In
                      such cases, we may make use of some of your personal key information as instructed by the client
                      company and retain the information until returning it to the client company or deleting it from
                      our system.
                    </li>
                    <li>
                      We may at times acquire commercially available lists or directories.
                    </li>
                    <li>
                      Mitsubishi Electric will not collect, use, or provide to any third party any personal
                      information such as the following without your consent.
                      Information concerning:
                      <ul style="list-style-type:disc;">
                        <li>Personal philosophies, beliefs, or religion</li>
                        <li>
                          Race, ethnicity, physical/mental disabilities, criminal records, or other information that may
                          lead to social discrimination
                        </li>
                        <li>Workers' right to organize, collective bargaining, or other collective activities </li>
                        <li>Participation in demonstrations, petitions, or the exercising of other political rights</li>
                        <li>Medical treatment or sexual practices.</li>
                        <li>Password</li>
                        <li>Financial information such as Bank account or credit card or debit card or other payment
                          instrument details</li>
                        <li>Biometric information</li>
                        </ul>
                    </li>
                  </ol>
                  <h4>3. How We Use your Personal Information </h4>
                  <ol>
                    <li>Mitsubishi Electric uses the personal & sensitive personal information we receive from
                      employee's, Business Partner's / service provider's, customer's to execute contracts and
                      transactions concluded between each of employee / Business Partner/ customer/ service provider and
                      Mitsubishi Electric. The information we receive from Customer on our ME-Connect mobile app
                      are generally name, company name (if any), residential / office address, state, city, contact no,
                      email address, etc (if any). The same is used for locating the related Business Partner who can
                      satisfy your requirement in your city and your contact details are used to contact Customer who
                      enquired about the product. </li>
                    <li>
                      Mitsubishi Electric uses personal information of Customer to provide with relevant information
                      that may be of interest such as information on our products, solutions, and associated services.
                    </li>
                    <li>Mitsubishi Electric uses personal & sensitive personal formation of business Partners (Channel
                      partners / distributors) / service provider /customer obtained during their appointment /
                      registration strictly for meeting its business obligation, extending other business-related
                      services through third Party and meeting other statutory obligation.</li>
                    <li>Mitsubishi Electric may at times be entrusted by a client company to process information or
                      perform other such activities on its behalf. The personal information we obtain as a result will
                      be used only for the activities with which we are entrusted. In such cases, Mitsubishi Electric
                      and the client company will be bound by a confidentiality agreement not to disclose the name of
                      the client company or details of the activity. </li>
                    <li>Mitsubishi Electric will not use personal information we receive from you for purposes other
                      than those specified at the time of collection.</li>
                  </ol>
                  <h4>4. When We Disclose or Provide your Personal Information to Third Parties </h4>
                  <div>
                    As a rule, Mitsubishi Electric does not disclose or provide the personal information we receive from
                    you to any third party, except in the following cases:
                  </div>
                  <ol>
                    <li>
                      When we have your consent.
                    </li>
                    <li>
                      When we employ a third party to handle all or part of the personal and  sensitive personal
                      information we have collected from employees & business Partners, to perform services that are
                      necessary to achieve our objectives and perform the services beneficial to employees & business
                      partners.
                    </li>
                    <li>
                      When personal information is provided in conjunction with the succession of business resulting
                      from a merger or other transfers of business.
                    </li>
                    <li>
                      When we are required by law to do so.
                    </li>
                    <li>
                      When it is necessary to do so to protect the life, body or property of people, and obtaining
                      your consent is difficult.
                    </li>
                    <li>
                      When it is necessary to do so to improve public health or promote the welfare or well-being of
                      children, and obtaining your consent is difficult.
                    </li>
                    <li>
                      When it is necessary to cooperate with national agencies, local governments, or their consigned
                      parties in the execution of legally prescribed business, but where obtaining your consent may
                      hinder execution of the said business.
                    </li>
                    <li>
                      To stop the provision of personal information to third parties without customer consent:
                      <div>
                        It may at times be necessary for Mitsubishi Electric to provide personal & personal sensitive
                        information received from employees, business partners & customers to Mitsubishi Electric
                        affiliates located both in India and abroad for business purpose.
                      </div>
                      <div>
                        Your consent to this Privacy Policy followed by Your submission of such information represents
                        Your agreement to that transfer.
                      </div>
                      <div>
                        The Company will take all steps reasonably necessary to ensure that Your data is treated
                        securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will
                        take place to an organization or a country unless there are adequate controls in place including
                        the security of Your data and other personal information.
                      </div>
                      <h4>Stopping provision of personal information to third parties</h4>
                      <div>In case employees or clients may request that their personal information no longer be
                        provided to third parties. You can write to Grievance Officer: </div>
                      <div style="margin-left:8px;">
                        Mr. Naveen Bhutani <br>
                        Deputy General Manager- Information Technology <br>
                        Mitsubishi Electric India Private Limited <br>
                        3rd Floor Tower A, Global Gateway, <br>
                        MG Road, Gurugram- 122002, Haryana, India.<br>
                        Phone: +91 124 6739 300 <br>
                        Email: Naveen.Bhutani@asia.meap.com<br>
                      </div>
                    </li>


                  </ol>
                  <h4>5. How We Protect your Personal Information</h4>
                  <div>
                    The security of your Personal Information is important to us. We will always hold your information
                    securely. Mitsubishi Electric employs administrative, technical, personal, and physical safeguards
                    to protect your personal information.
                  </div>
                  <ol>
                    <li>
                      Mitsubishi Electric employs necessary safety measures and takes necessary corrective actions to
                      protect your personal information from unauthorized access, loss, destruction, alteration, or
                      leakage. However, please be reminded that there is no fail-proof technology that can prevent
                      illegal access to personal information. While we strive to use commercially acceptable means to
                      protect your Personal Information, we cannot guarantee its absolute security.
                    </li>
                    <li>
                      Mitsubishi Electric employees receive regular education on safeguarding personal information.
                    </li>
                    <li>
                      In cases when client company is hired by Mitsubishi Electric to provide services on our behalf
                      are commissioned to handle personal information, we select appropriate contractors and require
                      them to sign all necessary  agreements (Non-Disclosure agreement) concerning the handling of
                      personal information.  The agreements are signed prior to any services We initiate with them and
                      will only use it in order to initiate and / or continue the services they are providing for us and
                      also employ all safety measures required by law.
                    </li>
                  </ol>
                  <h4>6. Questions Concerning Personal Information </h4>
                  <ol>
                    <li>
                      You may request the disclosure of any of your personal information in our possession. You may
                      also enquire about the results of such requests and request revisions when necessary.
                    </li>
                    <li>
                      You may contact us with questions or comments regarding our handling of personal information.
                    </li>
                    <li>
                      Mitsubishi Electric India Pvt. Ltd. will respond to any such request in an appropriate manner,
                      within reason. We have appointed the Grievance officer expressly for this purpose.
                    </li>
                    <div>If you have any questions or any Grievance relating to your personal information, you may contact
                    our Grievance Officer:
                    <div style="margin-left:8px;margin-bottom: 10px;">
                      Mr. Naveen Bhutani <br>
                      Deputy General Manager- Information Technology <br>
                      Mitsubishi Electric India Private Limited <br>
                      3rd Floor Tower A, Global Gateway, <br>
                      MG Road, Gurugram- 122002, Haryana, India.<br>
                      Phone: +91 124 6739 300 <br>
                      Email: Naveen.Bhutani@asia.meap.com<br>
                    </div>
                  </div>
                  </ol>
                  

                  <h4>7. Children </h4>
                  <div style="margin-bottom: 10px;">Children below the age of 18 years, without the consent of their parent or guardian should not
                    submit personal Information to the ME-Connect mobile app. While few, if any, of the materials are
                    directed towards children, Mitsubishi Electric India Pvt. Ltd. encourages all parents or guardians
                    to instruct their children in the safe and responsible use of their personal information</div>
                  <h4>8. Data Retention</h4>
                  <div style="margin-bottom: 10px;">We will retain your information for as long as you use the Application and for a reasonable time
                    thereafter depending on the nature of application. Please note that some or all of your provided
                    Data may be required in order for the Application to function properly.</div>
                  <h4>9. Questions or Comments</h4>
                  <div style="margin-bottom: 10px;">It is important to us to hear what visitors have to say about our services, and our policies. If
                    you have any questions, concerns, about our off-line and on-line products and services, please
                    contact us at https://mitsubishielectric.in/contact-us </div>
                  <h4>10. Disputes</h4>
                  <div style="margin-bottom: 10px;">All disputes under this Privacy Policy must be resolved under the Terms and Conditions and at
                    courts of New Delhi. </div>
                  <h4>11. Links to third-party mobile phone applications / sites</h4>
                  <div >Our Service may contain links to other websites / mobile phone application that are not operated
                    by us. If You click on a third party link, You will be directed to that third party’s site/mobile
                    phone application.</div>
                  <div style="margin-bottom: 10px;">
                    Please note that we have no control of such websites/mobile phone application. If you provide
                    information to a website/ mobile application to which we link, we are not responsible for its
                    protection and privacy. We strongly advise You to review the Privacy Policy of every site/mobile
                    phone application you visit. </div>
                  <h4>12. Amendment</h4>
                  <div>Mitsubishi Electric reserves the right to amend this Privacy Policy at any time. If Mitsubishi
                    Electric changes its privacy practices, a new Privacy Policy will reflect those changes and the
                    effective date of the revised Privacy Policy will be set forth in this paragraph. </div>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  name: "TermsAndConditions",
};
</script>
